import React, { useContext } from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ImpactContext } from '../../components/Layout';
import { Entity } from '@parallelpublicworks/entitysync/';

import { 
    RadioField
} from '../../components/library/entitysync'; 

function inputRadio() {
    const {user} = useContext(ImpactContext)
    return (
        <LibraryWrapper>
            <Entity componentId="user-entity" type="user--user" source={user}>
                <RadioField 
                    field="sample_radio_two" 
                    label="Sample Radio long" 
                    mood
                />
                <hr />
                <RadioField 
                    field="sample_radio_three" 
                    label="Sample Radio Error" 
                    error 
                    helperText="Please select an option" 
                    mood
                />
            </Entity>
        </LibraryWrapper>
    )
}
export default inputRadio
